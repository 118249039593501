<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>{{$t($route.name)}}</b-card-title>
        <b-button
            class=""
            variant='success'
            @click='createItem'
            v-if='resolvePermission("progouncer.create")'
          >
          <feather-icon
            icon="PlusIcon"
            size="15"
          >

          </feather-icon>
          {{$t('add new')}}
        </b-button>
    </b-card-header>
    <b-row class="mx-2">
      <b-col>
        <b-form-group
          :label='$t("Search")'
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            v-model='searchText'
          />
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group
          label="Basic Input"
          label-for="selectInput"
        >
          <b-form-select
            id='selectInput'
            v-model="selected"
            :options="options"
          />
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="options"
          />
        </b-form-group>
      </b-col> -->
      <b-col cols='1' class="align-self-center">
        <b-button 
          variant='primary'
          class="btn my-auto"
          :disabled ='loading'
          @click="filterItems"
        >
          <feather-icon
            icon='SearchIcon'
            size='16'
          ></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-row
      class="px-3 d-flex"
    >
      <div
        v-for="(presenter, preind) in items"
        :key="presenter.id"
        class="mb-3 pr-card-holder"
      >
        <PresentersCard
          :preind="preind"
          :presenter="presenter"
          :filterItems="filterItems"
          :openModal="openModal"
          :closeModal="closeModal"
          :confirmDeleteFullItem="confirmDeleteFullItem"
        ></PresentersCard>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {  BTable, BButton, BRow, BCol , 
  BCard, BCardTitle, BCardHeader, BCardFooter, 
  BFormGroup, BFormInput, BFormSelect,
  BPagination, 
  BPopover, BFormCheckbox,
  BAvatar
} from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';  
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PresentersCard from '@/@core/components/presenters/PresentersCard.vue';
export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard, BCardTitle, BCardHeader, BCardFooter,
    BFormGroup, BFormInput, BFormSelect,
    BPagination,
    BPopover, BFormCheckbox,
    BAvatar,
    PresentersCard
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('#')
        },
        {
          key: 'image',
          label: this.$t('photo')
        },
        {
          key: 'cover',
          label: this.$t('photo')
        },
        {
          key: 'titles',
          label: this.$t('name'),
          formatter: (value) => value ? value[this.$i18n.locale] : ''
        },
         {
          key: 'descriptions',
          label: this.$t('description'),
          formatter: (value) => value ? value[this.$i18n.locale] : ''
        },
        {
          key: 'programs',
          label: this.$t('Programs'),
        },
        {
          key: 'status',
          label: this.$t('status')
        },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: 'actions',
          label: this.$t('actions')
        },
      ],
      currentPage: 1,
      perPage: 1000,
      rows: 0,
      searchText: '',
      shitAss: ''
    }
  },
  props: ['openModal', 'closeModal', 'confirmDelete', 'confirmDeleteFullItem', 'alertMissingImages'],
  watch: {
    shitAss: function() {
      console.log(this.shitAss)
    }
  },
  created() {
    this.getPresenters({
      limitedBy: -1,
      all_locale: 1
    })
      .then(res => {
        this.getShows({
          limitedBy: 100,
        })
        // console.log(res)
        this.rows = res.data.results.length;
      }).catch(err => console.error(err))
  },

  methods: {
    ...mapActions(['getPresenters', 'deletePresenters', 'getShow', 'createPresenter', 'getShows', 'createShow']),
    filterItems() {
      setTimeout(() => {
        this.getPresenters({
          page: this.currentPage,
          keywords: this.searchText,
          today: this.today,
          limitedBy: -1,
          all_locale: 1
        }).then(res => {
          this.rows = res.data.results.total;      
        }).catch(err => {
  
        })
      }, 100)
    },
    editPresenterText(formData) {
      this.$store.commit('setModalLoading', true);
      this.createPresenter(formData)
      .then(res => {
        this.$store.commit('setModalLoading', true);
        this.closeModal();
        this.filterItems();
      })
    },
    editTitle(item) { 
      //* open modal form for editing text ar and en only
      this.openModal({}, 'TextEditForm', 'Edit Title');
      this.$store.commit('setModalOpts', {
        formFields: [ 
          { name: 'Title AR', type: 'text', model: 'titleAr', rules: '', cols: '12' },
          { name: 'Title EN', type: 'text', model: 'titleEn', rules: '', cols: '12' }
        ],
        submitFn: this.editPresenterText,
        item,
        key: 'title'
      })
    },
    editDescription(item) { 
      //* open modal form for editing text ar and en only
      this.openModal({}, 'TextEditForm', 'Edit Description');
      this.$store.commit('setModalOpts', {
        formFields: [ 
          { name: 'Description AR', type: 'textarea', model: 'descriptionAr', rules: '', cols: '12' },
          { name: 'Description EN', type: 'textarea', model: 'descriptionEn', rules: '', cols: '12' }
        ],
        submitFn: this.editPresenterText,
        item,
        key: 'description'
      })
    },
    changeStatus(row, value) {
      console.log("changeStatus => value:", value)
      this.createPresenter({
        ...row.item,
        ...row.item.contact,
        status: value
      })

    },
    openProgramsDropDown(row, showID) {
      // let showID = e.target.value;
      
      console.log("openProgramsDropDown => row:", row)
      //* find show to add presenter on
      this.getShow(showID)
      .then(showRes => {
        let announcers_id = row.item.programs.map(prog => prog.id);
        if(announcers_id.includes(showID)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Presenter already exist on this Program'),
              icon: '',
              variant: 'danger',
            },
          });

        } else {
          this.addNewPresenter(row, showID);
        }
      })
    },
    removeDeletedImages(show) {
      let coverImageExists = show.cover_image_id && show.cover_image_url != null
      let playerImageExists = show.player_image_id && show.player_image_url != null
      let tvImageExists = show.tv_image_id && show.tv_image_url != null;
      let missingImg = false;

      if(!coverImageExists) {
        show.cover_image_id = 0;
        missingImg = true;
      }
      if(!playerImageExists) {
        show.player_image_id = 0;
        missingImg = true;
      }
      if(!tvImageExists) {
        show.tv_image_id = 0;
        missingImg = true;
      }
      return {show, missingImg};
    },
    addNewPresenter(row, showID) {
      let newID = row.item.id;
      //* check and remove any image that is not deleted from files
      let {show, missingImg} = this.removeDeletedImages(this.show);
      //* add presenter to show
      this.createShow({
        ...show,
        ...this.show.social_links,
        announcers_id: [...this.show.announcers_id, Number(newID)]
      }).then(res => {
        this.filterItems();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Presenter added successfully'),
            icon: '',
            variant: 'success',
          },
        });
        if(missingImg) {
          this.alertMissingImages(show);
        }
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.results.message,
            icon: '',
            variant: 'danger',
          },
        });
      })
       
    },
    removePresenterFlow(item) {
      let removeID = item.id;
      let showID = item.showID;
      //* find show to add presenter on
      this.getShow(showID)
      .then(showRes => {
        //* check and remove any image that is not deleted from files
        let {show, missingImg} = this.removeDeletedImages(this.show);
        let announcers = this.show.announcers_id;
        
        let index = announcers.findIndex(id => id == removeID);
        if(index != -1) {
          announcers.splice(index, 1);
            return new Promise((resolve, reject) => {
              this.createShow({
                ...show,
                ...this.show.social_links,
                announcers_id: announcers
              }).then(res => {
                resolve(res);
                this.filterItems();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Presenter removed successfully'),
                    icon: '',
                    variant: 'success',
                  },
                });
                if(missingImg) {
                  setTimeout(() => {
                    this.alertMissingImages(show);
                  }, 50)
                }
              }).catch(err => {
                reject(err)
              })
              
            })
    
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Presenter was not found'),
                variant: 'danger',
              },
            });
          }
        })
    },
    confirmRemovePresenter(row, showID) {
      this.confirmDeleteFullItem(this.removePresenterFlow, {...row.item, showID})
    },
    showItem(row) {
      this.openModal(row, 'Show')
      // console.log(row)
    },
    createItem() {
      this.openModal({}, "CreatePresenter", 'Create Presenter');
      // this.$router.push({
      //   name: "CreateItem",
      //   params: {
      //     item: 'presenter'
      //   }
      // })
    },
    editItem(row) {
      // this.openModal(row, 'Edit')
      this.$router.push({
        name: "EditItem",
        params: {
          item: 'presenter',
          item_id: row.item.id
        }
      })
    },

    deleteItem(row) {
       this.confirmDelete(this.deletePresenters, row)
    }
  },

  computed: {
    ...mapGetters({
      items: 'presenters',
      show: 'show',
      shows: 'shows',
    }),
  }
}
</script>