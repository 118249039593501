<template>

<div class="pr-container">
  <div 
    class="pr-header"
    :class="{
      'edit-start': editHover['cover']
    }"  
  >
    <div
      class="pr-tools"
    >
      <b-form-checkbox 
        @change="(e) => changeStatus(e)"
        v-if='resolvePermission("show.edit")'
        switch
        v-model="presenter.status"
      >
      </b-form-checkbox>
      <feather-icon 
        icon="Trash2Icon"
        size="18"
        class="pointer text-danger pr-delete"
        @click="deleteItem"  
      ></feather-icon>
    </div>
    <div 
      class="pr-cover"
    >
      <img 
        :class="{
          'edit-start': editHover['cover']
        }" 
        width="180"
        height="135"
        :src="presenter.cover_image_url || placeHolder"
        @click="openDropzoneSelect('cover')"  
      />

    </div>
    <div 
      class="pr-image"
          
    >
      <img 
      @click="openDropzoneSelect('image')"
        :class="{
          'edit-start': editHover['image']
        }"
        width="55"
        height="55"
        :src="presenter.image_url || placeHolder"
      />
    </div>
  </div>

  <!--  -->
  <div class="pr-content">
    <h6 
      class="pointer" 
      @click="editTitle"
      :class="{
        'edit-start': editHover['title']
      }"
        v-if="presenter.titles"
    >
      {{ presenter.titles[locale] }}
    </h6>
    <p 
      class="pointer" 
      @click="editDescription"
      :class="{
        'edit-start': editHover['description']
      }"
      v-if="presenter.descriptions"
    >
      {{ presenter.descriptions[locale] }}
    </p>
  </div>

  <!--  -->
  <div>
    <h6 style="padding: 0 10px;"> {{ $t('Shows') }} </h6>
    <div class="d-flex align-items-center flex-wrap pr-programs">
      <span
        v-for="(prog, progInd) in presenter.programs"
        :key="prog.id"
        style="width: 20%"
      >
        <b-avatar 
          :id="`prog-${prog.id * preind}`"
          :src="prog.player_image_url || prog.cover_image_url || placeHolder"
          class="table-avatar"
          button
          @click="confirmRemovePresenter(prog.id)"
          size="2.5rem"
        >
        </b-avatar>
        <b-popover 
          :title="prog.title" 
          :target="`prog-${prog.id * preind}`" 
          triggers="hover"
          variant="dark"
          placement="auto"
        ></b-popover>
      </span>


      <b-avatar 
        class="pointer position-relative table-avatar-plus"
        button
        size="2.5rem"
      >
        <select 
          :id="`select-${presenter.id}`"
          class="pointer table-select-dropdown"
          @input="(e) => chooseShow(e)"
        >
          <option
            :value="null"
          > 
            {{ "Choose Show" }}
          </option>
          <option
            v-for="show in shows"
            :value="show.id"
          > 
            {{ show.title }}
          </option>
          <option
            v-for="show in shows"
            :value="show.id"
          > 
            {{ show.title }}
          </option>
        </select>
        <feather-icon icon="PlusIcon" />
      </b-avatar>
    </div>
  </div>

  <!--  -->
  <div>
    <h6 style="padding: 0 10px;"> {{ $t('Socials') }} </h6>
    <div class="pr-contact d-flex ">
      <span
        v-for="social in socialLinks"
        style="width: 20%"
        class="pointer position-relative"
        @click="editSocials(social)"
      >
        <span 
          v-if="editHover[social.key]"
          class="edit-ready img"  
        > 
          <feather-icon icon="EditIcon" size="12" class="m-1 text-primary"></feather-icon>
        </span>
        <img 
          :class="{
            'no-link': !presenter.contact[social.key],
          }"
          height="32"
          width="32"
          :src="social.icon"
        />
      </span>
    </div>
  </div>

</div>

</template>

<script>
import { 
  BCard,
  BAvatar, BPopover,
  BFormCheckbox
 } from 'bootstrap-vue';
import { mapGetters, mapActions } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BAvatar, BPopover,
    BFormCheckbox
  },
  data() {
    return {
      editHover: {
        facebook: false,
        instagram: false,
        twitter: false,
        whatsapp: false,
        youtube: false,
        title: false,
        description: false,
        image: false,
        cover: false,
      }
    }
  },
  props: ['presenter', 'filterItems', 'closeModal', 'openModal', 'confirmDeleteFullItem', 'preind'],
  mounted() {
    
  },
  methods: {
    ...mapActions(['createPresenter', 'getShow', 'createShow', 'deletePresenters']),
    editPresenterText(formData) {
      this.$store.commit('setModalLoading', true);
      this.createPresenter(formData)
      .then(res => {
        this.$store.commit('setModalLoading', true);
        this.closeModal();
        this.filterItems();
      })
    },
    editTitle(item) { 
      let currentTitle = this.editHover['title'];
      if(!currentTitle) {
        this.editHover['title'] = true;
        setTimeout(() => {
          this.editHover['title'] = false;
        }, 5000);
      } else {
        //* open modal form for editing text ar and en only
        this.openModal({}, 'TextEditForm', 'Edit Title');
        this.$store.commit('setModalOpts', {
          formFields: [ 
            { name: 'Title AR', type: 'text', model: 'titleAr', rules: '', cols: '12' },
            { name: 'Title EN', type: 'text', model: 'titleEn', rules: '', cols: '12' }
          ],
          submitFn: this.editPresenterText,
          item: this.presenter,
          key: 'title'
        })
      }
    },
    editDescription(item) { 
      let currentTitle = this.editHover['description'];
      if(!currentTitle) {
        this.editHover['description'] = true;
        setTimeout(() => {
          this.editHover['description'] = false;
        }, 5000);
      } else {

        //* open modal form for editing text ar and en only
        this.openModal({}, 'TextEditForm', 'Edit Description');
        this.$store.commit('setModalOpts', {
          formFields: [ 
            { name: 'Description AR', type: 'textarea', model: 'descriptionAr', rules: '', cols: '12' },
            { name: 'Description EN', type: 'textarea', model: 'descriptionEn', rules: '', cols: '12' }
          ],
          submitFn: this.editPresenterText,
          item: this.presenter,
          key: 'description'
        })
      }
    },
    changeStatus(value) {
      this.createPresenter({
        ...this.presenter,
        ...this.presenter.contact,
        status: value
      })
    },
    removePresenterFlow(item) {
      let removeID = item.id;
      let showID = item.showID;
      //* find show to add presenter on
      this.getShow(showID)
      .then(showRes => {
        //* check and remove any image that is not deleted from files
        let {show, missingImg} = this.removeDeletedImages(this.show);
        let announcers = this.show.announcers ? this.show.announcers.map(ann => ann.id) : [];
        let index = announcers.findIndex(id => id == removeID);
        if(index != -1) {
          announcers.splice(index, 1);
            return new Promise((resolve, reject) => {
              this.createShow({
                ...show,
                ...this.show.social_links,
                announcers_id: announcers
              }).then(res => {
                resolve(res);
                this.filterItems();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Presenter removed successfully'),
                    icon: '',
                    variant: 'success',
                  },
                });
              }).catch(err => {
                reject(err)
              })
              
            })
    
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Presenter was not found'),
                variant: 'danger',
              },
            });
          }
        })
    },
    confirmRemovePresenter(showID) {
      this.confirmDeleteFullItem(this.removePresenterFlow, {...this.presenter, showID})
    },
    chooseShow(event) {
      let showID = event.target.value;
      let row = this.presenter;
      //* find show to add presenter on
      this.getShow(showID)
      .then(showRes => {
        let announcers_id = row.programs.map(prog => prog.id);
        if(announcers_id.includes(Number(showID))) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Presenter already exist on this Program'),
              icon: '',
              variant: 'danger',
            },
          });

        } else {
          this.addNewPresenter(row, showID);
        }
      })
    },
    removeDeletedImages(show) {
      let coverImageExists = show.cover_image_id && show.cover_image_url != null
      let playerImageExists = show.player_image_id && show.player_image_url != null
      let tvImageExists = show.tv_image_id && show.tv_image_url != null;
      let missingImg = false;

      if(!coverImageExists) {
        show.cover_image_id = 0;
        missingImg = true;
      }
      if(!playerImageExists) {
        show.player_image_id = 0;
        missingImg = true;
      }
      if(!tvImageExists) {
        show.tv_image_id = 0;
        missingImg = true;
      }
      return {show, missingImg};
    },
    addNewPresenter(row, showID) {
      let newID = row.id;
      //* check and remove any image that is not deleted from files
      let {show, missingImg} = this.removeDeletedImages(this.show);
      //* add presenter to show
      let announcers = this.show.announcers ? this.show.announcers.map(ann => ann.id) : [];
      this.createShow({
        ...show,
        ...this.show.social_links,
        announcers_id: [...announcers, Number(newID)]
      }).then(res => {
        this.filterItems();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Presenter added successfully'),
            icon: '',
            variant: 'success',
          },
        });
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.results.message,
            icon: '',
            variant: 'danger',
          },
        });
      })
       
    },
    editPresenterText(formData) {
      this.$store.commit('setModalLoading', true);
      this.createPresenter(formData)
      .then(res => {
        this.$store.commit('setModalLoading', true);
        this.closeModal();
        this.filterItems();
      })
    },
    editSocials(social) {
      let currentSocial = this.editHover[social.key];
      if(!currentSocial){
        this.editHover[social.key] = true;
        setTimeout(() => {
          this.editHover[social.key] = false;
        }, 5000)
      } else {
        this.openModal({}, 'TextEditForm', 'Edit Socials');
        this.$store.commit('setModalOpts', {
          formFields: [ 
            { name: social.name, type: 'text', model: social.key, rules: '', cols: '12' },
          ],
          submitFn: this.editPresenterText,
          item: this.presenter,
          key: social.key
        })
      }
    },
    openDropzoneSelect(imgType) {
      let currentImg = this.editHover[imgType];
      if(!currentImg){
        this.editHover[imgType] = true;
        setTimeout(() => {
          this.editHover[imgType] = false;
        }, 5000)
      } else {
        let currentFile = this.presenter[`${imgType}_img`];
        // if(currentFile != null) {
          this.presenter.imgType = imgType;
          this.openModal({
            item: {
              id: this.presenter.id,
              titles: this.presenter.titles,
              descriptions: this.presenter.descriptions,
              ...this.presenter.contact,

              selectable: false,
              selectFile: this.resolveSelectFunction(imgType),
              name: imgType,
              selectable: { id: this.resolveSelectedImg(this.presenter, imgType) },
            }
          }, 'SelectImage', 'Edit Show Images');
        // }
        
      }
    },
    resolveSelectedImg(row, imgType) {
      if(imgType == 'cover') {
        return row.cover_image_id;
      }
      if(imgType == 'image') {
        return row.image_id;
      }
    },
    resolveSelectFunction(imgType) {
      if(imgType == 'cover') {
        return this.selectCover;
      }
      if(imgType == 'image') {
        return this.selectImage;
      }
    },

    selectCover(file, formObj) {
      
      if(formObj.id) {
        delete formObj.image_id;
        this.createPresenter({
          ...formObj,
          cover_image_id: file.id
        }).then(res => {
          this.filterItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Cover Image Updated successfully'),
              icon: '',
              variant: 'success',
            },
          });
        })
      }
    },
    selectImage(file, formObj) {
      
      if(formObj.id) {
        delete formObj.cover_image_id;
        this.createPresenter({
          ...formObj,
          image_id: file.id
        }).then(res => {
          this.filterItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Image Updated successfully'),
              icon: '',
              variant: 'success',
            },
          });
        })
      } 
    },
    deleteItem() {
      let text = this.$t('This Presenter will be removed from all the shows currently attached to him/her.');
      this.confirmDeleteFullItem(this.deleteItemFlow, this.presenter, text);
    },
    deleteItemFlow() {
      let removePromises = [];
      //* make promises for all shows 
      this.presenter.programs.forEach(show => {
        let announcers = show.announcers ? show.announcers.map(ann => ann.id) : [];
        let index = announcers.findIndex(id => id == this.presenter.id);
        if(index != -1) {
          announcers.splice(index, 1);
          
          let promise = this.createShow({
            ...show,
            announcers_id: announcers
          });

          removePromises.push(promise);
        }
      })
      //* add promise for removing the presenter
      removePromises.push(this.deletePresenters(this.presenter.id));
      //* run promisies and get refresh list
      return Promise.all(
        removePromises
      );
    }
  },
  computed: {
    ...mapGetters(['shows', 'show']),
    locale() {
      return this.$i18n.locale;
    },
    socialLinks() {
      return [
        { name: 'facebook', icon: require('@/assets/socialicons/facebook.png'), key: 'facebook' },
        { name: 'whatsapp', icon: require('@/assets/socialicons/whatsapp.png'), key: 'whatsapp' },
        { name: 'youtube', icon: require('@/assets/socialicons/youtube.png'), key: 'youtube' },
        { name: 'instagram', icon: require('@/assets/socialicons/instagram.png'), key: 'instagram' },
        { name: 'X', icon: require('@/assets/socialicons/X.png'), key: 'twitter' },
      ]
    },
  }
}
</script>