var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pr-container"},[_c('div',{staticClass:"pr-header",class:{
      'edit-start': _vm.editHover['cover']
    }},[_c('div',{staticClass:"pr-tools"},[(_vm.resolvePermission("show.edit"))?_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":function (e) { return _vm.changeStatus(e); }},model:{value:(_vm.presenter.status),callback:function ($$v) {_vm.$set(_vm.presenter, "status", $$v)},expression:"presenter.status"}}):_vm._e(),_c('feather-icon',{staticClass:"pointer text-danger pr-delete",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":_vm.deleteItem}})],1),_c('div',{staticClass:"pr-cover"},[_c('img',{class:{
          'edit-start': _vm.editHover['cover']
        },attrs:{"width":"180","height":"135","src":_vm.presenter.cover_image_url || _vm.placeHolder},on:{"click":function($event){return _vm.openDropzoneSelect('cover')}}})]),_c('div',{staticClass:"pr-image"},[_c('img',{class:{
          'edit-start': _vm.editHover['image']
        },attrs:{"width":"55","height":"55","src":_vm.presenter.image_url || _vm.placeHolder},on:{"click":function($event){return _vm.openDropzoneSelect('image')}}})])]),_c('div',{staticClass:"pr-content"},[(_vm.presenter.titles)?_c('h6',{staticClass:"pointer",class:{
        'edit-start': _vm.editHover['title']
      },on:{"click":_vm.editTitle}},[_vm._v(" "+_vm._s(_vm.presenter.titles[_vm.locale])+" ")]):_vm._e(),(_vm.presenter.descriptions)?_c('p',{staticClass:"pointer",class:{
        'edit-start': _vm.editHover['description']
      },on:{"click":_vm.editDescription}},[_vm._v(" "+_vm._s(_vm.presenter.descriptions[_vm.locale])+" ")]):_vm._e()]),_c('div',[_c('h6',{staticStyle:{"padding":"0 10px"}},[_vm._v(" "+_vm._s(_vm.$t('Shows'))+" ")]),_c('div',{staticClass:"d-flex align-items-center flex-wrap pr-programs"},[_vm._l((_vm.presenter.programs),function(prog,progInd){return _c('span',{key:prog.id,staticStyle:{"width":"20%"}},[_c('b-avatar',{staticClass:"table-avatar",attrs:{"id":("prog-" + (prog.id * _vm.preind)),"src":prog.player_image_url || prog.cover_image_url || _vm.placeHolder,"button":"","size":"2.5rem"},on:{"click":function($event){return _vm.confirmRemovePresenter(prog.id)}}}),_c('b-popover',{attrs:{"title":prog.title,"target":("prog-" + (prog.id * _vm.preind)),"triggers":"hover","variant":"dark","placement":"auto"}})],1)}),_c('b-avatar',{staticClass:"pointer position-relative table-avatar-plus",attrs:{"button":"","size":"2.5rem"}},[_c('select',{staticClass:"pointer table-select-dropdown",attrs:{"id":("select-" + (_vm.presenter.id))},on:{"input":function (e) { return _vm.chooseShow(e); }}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s("Choose Show")+" ")]),_vm._l((_vm.shows),function(show){return _c('option',{domProps:{"value":show.id}},[_vm._v(" "+_vm._s(show.title)+" ")])}),_vm._l((_vm.shows),function(show){return _c('option',{domProps:{"value":show.id}},[_vm._v(" "+_vm._s(show.title)+" ")])})],2),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],2)]),_c('div',[_c('h6',{staticStyle:{"padding":"0 10px"}},[_vm._v(" "+_vm._s(_vm.$t('Socials'))+" ")]),_c('div',{staticClass:"pr-contact d-flex "},_vm._l((_vm.socialLinks),function(social){return _c('span',{staticClass:"pointer position-relative",staticStyle:{"width":"20%"},on:{"click":function($event){return _vm.editSocials(social)}}},[(_vm.editHover[social.key])?_c('span',{staticClass:"edit-ready img"},[_c('feather-icon',{staticClass:"m-1 text-primary",attrs:{"icon":"EditIcon","size":"12"}})],1):_vm._e(),_c('img',{class:{
            'no-link': !_vm.presenter.contact[social.key],
          },attrs:{"height":"32","width":"32","src":social.icon}})])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }